import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const Project = () => {

  const onProjectClicked = (project) => {
    if (project.url) {
      window.open(project.url, "_blank")
    }
  }

  return (
    <div className="section" id="project">
      <div className="container">
        <div className="work-wrapper">
          <Fade bottom>
            <h1>Outsource Projects</h1>
          </Fade>
          <div className="grid-small w-100">
            <Fade bottom cascade>
              {data.projects.map((project, index) => <div key={index} className="project-card">
                <img className="project-img" src={project.imageSrc} />
                <div className="project-name">{project.title}</div>
                <code>{project.category}</code>
                <div className="project-source">{project.source}</div>
                {project.links && <div className="project-links">
                  {project.links.map((link, index) => (
                    <div className="link">
                      <a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="link-icon"><link.icon /></span>
                      </a>
                    </div>
                  ))}
                </div>}
                {!project.online && <span className="project-indicator">Discountinued</span>}
              </div>
              )}
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project

/**
 *
 *
                  key={index}
                  heading={project.title}
                  paragraph={project.para}
                  imgUrl={project.imageSrc}
                  projectLink={project.url}
 */