import React from "react"
import Fade from "react-reveal/Fade"

import data from "../yourdata"

const Work = () => {

  const onProjectClicked = (project) => {
    if (project.url) {
      window.open(project.url, "_blank")
    }
  }

  return (
    <div className="section" id="product">
      <div className="container">
        <div className="work-wrapper">
          <Fade bottom>
            <h1>My Project</h1>
          </Fade>

          <div className="grid">
            <Fade bottom cascade>
              {data.products.map((project, index) => {

                if (project.hidden) {
                  return <div></div>
                }
                return <div key={index} className="project-card" onClick={() => {
                  onProjectClicked(project)
                }}>
                  <img className="project-img" src={project.imageSrc} />
                  <div className="project-name">{project.title}</div>
                  <code>{project.category}</code>
                  <div className="project-source">{project.source}</div>
                  {project.links && <div className="project-links">
                    {project.links.map((link, index) => (
                      <div className="link">
                        <a
                          key={index}
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="link-icon"><link.icon /></span>
                        </a>
                      </div>
                    ))}
                  </div>}
                  {!project.online && <span className="project-indicator">Discountinued</span>}
                </div>
              }
              )}
            </Fade>
            <span className="space" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Work
